<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    :variant="`transparent`"
    :opacity="0.5"
    :blur="`10px`"
    rounded="sm"
    spinner-type="grow"
  >
    <section>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              xl="9"
              md="8"
            >
              <b-card no-body>
                <b-card-body>
                  <div class="row">
                    <div class="col-12">
                      <validation-provider
                        #default="validationContext"
                        name="title"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.title')"
                          label-for="title"
                        >
                          <b-form-input
                            id="title"
                            v-model="inputData.title"
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('common.form.please_insert_data')"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider
                        #default="validationContext"
                        name="order"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.display_order')"
                          label-for="order"
                        >
                          <b-form-input
                            id="order"
                            v-model="inputData.order"
                            :state="getValidationState(validationContext)"
                            :placeholder="$t('common.form.please_insert_data')"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider
                        #default="validationContext"
                        name="categoryDocumentID"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.category')"
                          label-for="categoryDocumentID"
                        >
                          <b-form-select
                            v-model="categorySelected"
                            :options="listCategories"
                            :state="categorySelected === null ? false : true"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <b-form-group
                        :label="$t('common.general.file')"
                        label-for="file"
                      >
                        <b-form-file
                          v-model="documentFile"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        />
                      </b-form-group>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="4"
              xl="3"
              class="invoice-actions mt-md-0 mt-2"
            >
              <b-card>
                <b-form-checkbox
                  v-model="inputData.statusCode"
                  value="ACTIVE"
                  unchecked-value="INACTIVE"
                  class="custom-control-primary mb-75"
                  name="check-button"
                  switch
                  inline
                >{{ $t("common.form.publish") }}
                </b-form-checkbox>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                >
                  {{ $t("common.form.save") }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
import {
  BFormFile,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
  BFormCheckbox,
  BOverlay,
  BFormSelect,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import categoryDocumentServices from '@/services/categoryDocumentServices';
import documentServices from '@/services/documentServices';
import commonServices from '@/services/commonServices';
import enums from '@/services/enumsServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: {
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BOverlay,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  data() {
    return {
      isLoading: false,
      id: this.$route.query.id,
      inputData: {},
      required,
      documentFile: null,
      isUpload: false,
      categorySelected: null,
      listCategories: [],
    };
  },
  watch: {
    data(value) {
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  async mounted() {
    if (this.id) {
      await this.getDetails();
    }
  },
  async created() {
    await this.initData();
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      if (!this.inputData.statusCode) {
        this.inputData.statusCode = 'INACTIVE';
      }
      this.inputData.categoryDocumentID = this.categorySelected;
      if (this.documentFile) {
        await commonServices
          .uploadFile(this.documentFile, 'document', enums.levelS3.public)
          .then((result) => {
            this.isUpload = false;
            this.inputData.file = result;
          })
          .finally(() => {
            this.uploading = false;
          });
      }
      await documentServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.$router.push({ name: 'list-documents' });
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async upload($event) {
      this.isUpload = true;
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = async () => {
        console.log($event);
        // await commonServices
        //   .uploadImage($event, 'page', enums.levelS3.public)
        //   .then((result) => {
        //     this.isUpload = false;
        //     this.inputData.image = result;
        //   })
        //   .finally(() => {
        //     this.uploading = false;
        //   });
      };
    },
    async initData() {
      this.isLoadingTable = true;
      this.listCategories = [];
      try {
        await categoryDocumentServices
          .getList()
          .then((result) => {
            if (result) {
              const list = result.data.listCategoryDocuments.items;
              if (list.length > 0) {
                list.forEach((element) => {
                  this.listCategories.push({
                    value: element.id,
                    text: element.title,
                  });
                });
              }
            }
          })
          .catch(() => {})
          .finally(() => {
            this.isLoadingTable = false;
          });
      } finally {
        this.isLoadingTable = false;
      }
    },
    async getDetails() {
      this.isLoading = true;
      await documentServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getDocument;
            this.categorySelected = this.inputData.categoryDocumentID;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
